import axios from 'axios';
// import { store } from 'services/redux/store';

// import { logout } from 'services/redux/auth.slice';

import { BASE_URL, API_VERSION } from './CONSTANTS';
const getData = (res) => res.data;
let api;

function init({ baseURL = BASE_URL, axiosOptions = { headers: {} } } = {}) {
  api = axios.create({
    baseURL,
    ...axiosOptions,
    headers: {
      ...axiosOptions.headers,
    },

    withCredentials: true,
  });
  api.interceptors.request.use(
    (config) => {
      const token = window.localStorage.getItem('token');
      const anonymousId = localStorage.getItem('anonymousId');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (anonymousId) {
        config.headers.anonymousId = anonymousId;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          localStorage.removeItem('token');
          // store.dispatch(logout());
          window.location.href = '/';
        } catch (e) {
          console.log(e);
        }
      }
      return Promise.reject(error);
    }
  );
}

export { init, api, getData };
